import React, { useState } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import ContactUs from "../ContactUs";
import LazyLoad from 'react-lazyload';

// Styled components
const ProductCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#f9f9f9", // Light background
  color: "#333", // Dark text color for readability
  transition: "background-color 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", // Shadow effect on hover
    backgroundColor: "#f1f1f1",
  },
  textAlign: "center",
  border: "none",
  borderRadius: "20px",
}));

// Apply serif fonts and adjust weights
const TitleText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Cinzel', serif", // Serif font for titles
  fontWeight: 700, // Bold weight for titles
  textAlign: "center", // Center align for titles
}));

const BodyText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Lora', serif", // Lora for body text
  fontWeight: 400, // Lighter weight for body text
  lineHeight: 1.6, // Better readability
  textAlign: "justify", // Justify align for body text
}));

const ProductShowcase = ({ products }) => {
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const [enquireDialogOpen, setEnquireDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenProductDialog = (product) => {
    setSelectedProduct(product);
    setProductDialogOpen(true);
  };

  const handleCloseProductDialog = () => {
    setProductDialogOpen(false);
    setSelectedProduct(null);
  };

  const handleEnquireNow = () => {
    handleCloseProductDialog();
    setEnquireDialogOpen(true);
  };

  const handleCloseEnquireDialog = () => {
    setEnquireDialogOpen(false);
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '36px' }}>
        {products.map((product) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={product.name}>
            <ProductCard onClick={() => handleOpenProductDialog(product)}>
              <LazyLoad height={200} offset={100}>
                <CardMedia
                  component="img"
                  image={product.thumbnail || product.imageWebP || product.image}
                  alt={product.name}
                  style={{ width: "100%", objectFit: "contain" }}
                  loading="lazy"
                />
              </LazyLoad>
              <CardContent>
                <TitleText variant={isSmallScreen ? "body1" : "h6"}>
                  {product.name}
                </TitleText>
              </CardContent>
            </ProductCard>
          </Grid>
        ))}
      </Grid>

      <Dialog open={productDialogOpen} onClose={handleCloseProductDialog} maxWidth="md" fullWidth>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            gap: "16px",
            backgroundColor: "#ffffff", // White background
            color: "#333", // Dark text color
            padding: isSmallScreen ? "16px" : "24px",
          }}
        >
          <IconButton onClick={handleCloseProductDialog} style={{ position: "absolute", top: 8, right: 8 }}>
            <CloseIcon style={{ color: "#333" }} />
          </IconButton>

          {selectedProduct && (
            <>
              <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                <CardMedia
                  component="img"
                  image={selectedProduct.imageWebP || selectedProduct.image}
                  alt={selectedProduct.name}
                  style={{
                    width: "100%",
                    maxHeight: isSmallScreen ? "300px" : "400px",
                    objectFit: "contain",
                    marginBottom: "16px",
                  }}
                  loading="lazy"
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <TitleText variant={isSmallScreen ? "h6" : "h5"} gutterBottom>
                  {selectedProduct.name}
                </TitleText>
                <BodyText variant="body2" style={{ marginBottom: "16px", fontSize: isSmallScreen ? "0.9rem" : "1rem" }}>
                  {selectedProduct.description}
                </BodyText>
              </Grid>
            </>
          )}
        </DialogContent>

        <DialogActions style={{ justifyContent: "center", padding: "16px", backgroundColor: "#ffffff" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#8bc34a", // Softer green button
              fontSize: isSmallScreen ? "0.8rem" : ".9rem",
              padding: isSmallScreen ? "8px 12px" : "8px 18px",
            }}
            onClick={handleEnquireNow}
          >
            Enquire Now
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={enquireDialogOpen} onClose={handleCloseEnquireDialog} maxWidth="sm" fullWidth>
        <ContactUs />
      </Dialog>
    </>
  );
};

export default ProductShowcase;
